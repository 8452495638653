<template>
  <div class="visitor-action" @click="onInitiateCall($event)">
    <div class="visitor-action--call" :class="actionClass">
      <div v-if="!isActive" class="visitor-action--call--overlay">
        <img src="@/assets/images/vega/hand.png" />
      </div>
      <img v-else src="@/assets/images/vega/hand.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitorAction',
  props: {
    isCallStarted: {
      type: Boolean,
      default: false
    },
    online: {
      type: Boolean,
      default: false
    },
    isVegaWidgetEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isActive() {
      return this.online && this.isVegaActivated && this.isVegaWidgetEnabled && !this.isVegaPaused && !this.vegaWillExceedDailyLimit
    },
    actionClass() {
      return this.isActive ? (this.isCallStarted ? 'visitor-action--call--active' : 'visitor-action--call--online') : 'visitor-action--call--disabled'
    }
  },
  methods: {
    onInitiateCall(event) {
      event.stopPropagation()

      if (this.isActive && !this.isCallStarted) {
        this.$emit('initiate-call')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visitor-action {
  display: inline-block;
  width: 38px !important;
  height: 38px !important;

  &--call {
    position: relative;
    width: 38px;
    height: 38px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    border-style: solid;
    border-width: 2px;

    &--online {
      border-color: #2bff6f;
    }

    &--active {
      border-color: rgb(178, 178, 178);

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
      }

      &::before {
        top: -3px;
        left: -4px;
        width: 10px;
        height: 10px;
        background: rgb(43, 255, 111);
        border-radius: 50%;
        z-index: 2;
      }

      &::after {
        top: -4.5px;
        left: -5.5px;
        width: 13px;
        height: 13px;
        background: rgba(43, 255, 111, 0.36);
        box-shadow: 0 0 0 rgba(43, 255, 111, 0.36);
        animation: pulse 2s infinite;
      }
    }

    &--disabled {
      border-color: #e6ecf1;
      background-color: #e6ecf1;
    }

    &--overlay {
      position: relative;
      width: 15px;
      height: 16px;
    }

    &--overlay::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      border-radius: 6px;
      background: rgba(230, 236, 241, 0.4);
    }
  }
}
</style>
