var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "visitor-action",
      on: {
        click: function ($event) {
          return _vm.onInitiateCall($event)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "visitor-action--call", class: _vm.actionClass },
        [
          !_vm.isActive
            ? _c("div", { staticClass: "visitor-action--call--overlay" }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/vega/hand.png") },
                }),
              ])
            : _c("img", {
                attrs: { src: require("@/assets/images/vega/hand.png") },
              }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }