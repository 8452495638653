<template>
  <div class="visitor-action" @click="onShowVegaPopup($event)">
    <div class="visitor-action--call" :class="actionClass">
      <div v-if="!isActive" class="visitor-action--call--overlay">
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 64 64" fill="none" stroke="black" stroke-width="4">
          <rect x="16" y="16" width="24" height="24" rx="4" ry="4" fill="none" />
          <rect x="24" y="24" width="24" height="24" rx="4" ry="4" fill="none" />
          <path d="M32 28 L42 28 L42 38" stroke="black" stroke-linecap="round" />
        </svg>
      </div>

      <svg v-else xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 64 64" fill="none" stroke="black" stroke-width="4">
        <rect x="16" y="16" width="24" height="24" rx="4" ry="4" fill="none" />
        <rect x="24" y="24" width="24" height="24" rx="4" ry="4" fill="none" />
        <path d="M32 28 L42 28 L42 38" stroke="black" stroke-linecap="round" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitorAction',
  props: {
    isCallStarted: {
      type: Boolean,
      default: false
    },
    online: {
      type: Boolean,
      default: false
    },
    isVegaWidgetEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isActive() {
      return this.online && this.isVegaActivated && this.isVegaWidgetEnabled && !this.isVegaPaused && !this.vegaWillExceedDailyLimit
    },
    actionClass() {
      return this.isActive ? (this.isCallStarted ? 'visitor-action--call--active' : 'visitor-action--call--online') : 'visitor-action--call--disabled'
    }
  },
  methods: {
    onShowVegaPopup(event) {
      event.stopPropagation()

      if (this.isActive && !this.isCallStarted) {
        this.$emit('show-vega-popup')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visitor-action {
  display: inline-block;
  width: 38px !important;
  height: 38px !important;

  &--call {
    position: relative;
    width: 38px;
    height: 38px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    border-style: solid;
    border-width: 2px;

    &--online {
      border-color: #2bff6f;
    }

    &--active {
      border-color: rgb(178, 178, 178);

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
      }

      &::before {
        top: -3px;
        left: -4px;
        width: 10px;
        height: 10px;
        background: rgb(43, 255, 111);
        border-radius: 50%;
        z-index: 2;
      }

      &::after {
        top: -4.5px;
        left: -5.5px;
        width: 13px;
        height: 13px;
        background: rgba(43, 255, 111, 0.36);
        box-shadow: 0 0 0 rgba(43, 255, 111, 0.36);
        animation: pulse 2s infinite;
      }
    }

    &--disabled {
      border-color: #e6ecf1;
      background-color: #e6ecf1;
    }

    &--overlay {
      position: relative;
      width: 38px;
      height: 38px;
    }

    &--overlay::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      border-radius: 6px;
      background: rgba(230, 236, 241, 0.4);
    }
  }
}
</style>
