var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "visitor-action",
      on: {
        click: function ($event) {
          return _vm.onShowVegaPopup($event)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "visitor-action--call", class: _vm.actionClass },
        [
          !_vm.isActive
            ? _c("div", { staticClass: "visitor-action--call--overlay" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "38",
                      height: "38",
                      viewBox: "0 0 64 64",
                      fill: "none",
                      stroke: "black",
                      "stroke-width": "4",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        x: "16",
                        y: "16",
                        width: "24",
                        height: "24",
                        rx: "4",
                        ry: "4",
                        fill: "none",
                      },
                    }),
                    _c("rect", {
                      attrs: {
                        x: "24",
                        y: "24",
                        width: "24",
                        height: "24",
                        rx: "4",
                        ry: "4",
                        fill: "none",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M32 28 L42 28 L42 38",
                        stroke: "black",
                        "stroke-linecap": "round",
                      },
                    }),
                  ]
                ),
              ])
            : _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "38",
                    height: "38",
                    viewBox: "0 0 64 64",
                    fill: "none",
                    stroke: "black",
                    "stroke-width": "4",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      x: "16",
                      y: "16",
                      width: "24",
                      height: "24",
                      rx: "4",
                      ry: "4",
                      fill: "none",
                    },
                  }),
                  _c("rect", {
                    attrs: {
                      x: "24",
                      y: "24",
                      width: "24",
                      height: "24",
                      rx: "4",
                      ry: "4",
                      fill: "none",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M32 28 L42 28 L42 38",
                      stroke: "black",
                      "stroke-linecap": "round",
                    },
                  }),
                ]
              ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }