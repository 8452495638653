<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" :fill="circleColor" />
    <path
      d="M13.5 9.50049H6.5C5.94772 9.50049 5.5 9.9482 5.5 10.5005V14.0005C5.5 14.5528 5.94772 15.0005 6.5 15.0005H13.5C14.0523 15.0005 14.5 14.5528 14.5 14.0005V10.5005C14.5 9.9482 14.0523 9.50049 13.5 9.50049Z"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.49805 9.5V7.5C7.49805 6.83696 7.76144 6.20107 8.23028 5.73223C8.69912 5.26339 9.33501 5 9.99805 5C10.6611 5 11.297 5.26339 11.7658 5.73223C12.2347 6.20107 12.498 6.83696 12.498 7.5V9.5"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SmallLockIcon',
  props: {
    circleColor: {
      type: String,
      default: '#3B86F7',
      required: false
    },
    color: {
      type: String,
      default: '#fff',
      required: false
    },
    width: {
      type: Number,
      default: 20,
      required: false
    },
    height: {
      type: Number,
      default: 20,
      required: false
    }
  }
}
</script>
